import "../styles/TempStaffing.scss";
import Footer from "../components/Footer";
import ReadyBanner from "../components/ReadyBanner";

function TempStaffing() {
  return (
    <section className="temp-staffing">
      <div className="container">
        <h1 className="main-title">
          STAY AGILE WITH RELIABLE TEMPORARY STAFFING SOLUTIONS
        </h1>
        <div className="_flex gap-30">
          <p className="description">
            At Eboo, we understand that businesses often face fluctuating
            demands and short-term staffing needs. Our Temporary Staffing
            service offers a reliable and agile solution to help you bridge the
            gaps and ensure uninterrupted operations.
          </p>
          <p className="description">
            Whether you require additional support during peak seasons, special
            projects, or unexpected absences, our skilled temporary staff is
            ready to step in and contribute to your success.
          </p>
        </div>

        <div className="on-demand">
          <h1 className="on-demand-title">
            Access to On-Demand <br className="hidden-md" /> Specialized Skills
          </h1>

          <div className="_flex gap-30">
            <div className="card">
              <h1 className="title">
                Flexibility <br className="hidden-md" /> and Agility
              </h1>
              <p className="description">
                Our Temporary Staffing service provides you with the flexibility
                to scale your workforce up or down as per your changing needs.
                This ensures that you have the right number of skilled
                professionals to meet your business demands without the
                long-term commitments.
              </p>
            </div>
            <div className="card">
              <h1 className="title">
                Quick <br className="hidden-md" /> Turnaround
              </h1>
              <p className="description">
                We understand the urgency of your staffing requirements. Our
                extensive network of pre-screened temporary staff enables us to
                provide you with suitable candidates swiftly, minimizing
                downtime and allowing you to keep your operations running
                smoothly.
              </p>
            </div>
            <div className="card">
              <h1 className="title">
                Skilled and Reliable <br className="hidden-md" /> Professionals
              </h1>
              <p className="description">
                We pride ourselves on sourcing and vetting talented individuals
                who possess the necessary skills and expertise for the job. Our
                temporary staff members are reliable, adaptable, and equipped to
                hit the ground running, making an immediate impact on your team.
              </p>
            </div>
          </div>
        </div>

        <div className="_flex experience gap-30">
          <h1 className="sub-title">
            Experience Temporary <br className="hidden-md" /> Staffing Excellence
          </h1>

          <div>
            <p className="description">
              With years of experience in the staffing industry, we have
              developed a strong reputation for delivering reliable temporary
              staff to businesses across various sectors.
            </p>
            <p className="description mt">
              Our team understands the unique challenges and dynamics of
              temporary staffing, allowing us to find the right candidates who
              seamlessly integrate into your team and contribute to your
              success.
            </p>
          </div>
        </div>

        <ReadyBanner />
      </div>
      <Footer />
    </section>
  );
}

export default TempStaffing;
