import "../styles/ExecutiveSearch.scss";
import Footer from "../components/Footer";
import ReadyBanner from "../components/ReadyBanner";
function ExecutiveSearch() {
  return (
    <section className="executive-search">
      <div className="container">
        <h1 className="main-title">
          ELEVATE YOUR LEADERSHIP <br className="hidden-md" /> WITH EXCEPTIONAL{" "}
          <br className="hidden-md" />
          EXECUTIVES
        </h1>
        <div className="_flex">
          <div className="google-images">
            <img
              className="main-image"
              src="/assets/images/tablet-google.png"
              alt="google search"
            />
            <img
              className="absolute-image"
              src="/assets/images/search-bar.png"
              alt="search bar"
            />
          </div>
          <div className="content">
            <h1 className="sub-title">
              Discover the Difference <br className="hidden-md" /> of Elite
              Executive <br className="hidden-md" /> Search Services
            </h1>
            <div className="description">
              At Eboo, we specialize in executive search services designed to
              help you find visionary leaders who can take your organization to
              new heights. Our experienced team understands the critical role
              that executives play in driving success and growth. Discover how
              our executive search solutions can connect you with exceptional
              talent to elevate your leadership team.
            </div>
          </div>
        </div>

        <div className="_flex part-2 col-reverse-md">
          <div className="content">
            <h1 className="sub-title">
              Unparalleled Expertise in Identifying Executive Talent
            </h1>
            <div className="description">
              Our executive search services are backed by years of experience in
              placing top-level executives across industries. We have a deep
              understanding of the unique skills, expertise, and qualities that
              exceptional leaders possess.
            </div>
          </div>
          <div className="team-images">
            <img
              className="main-image"
              src="/assets/images/laptop.png"
              alt="laptop"
            />
            <img
              className="absolute-image"
              src="/assets/images/talented-team.png"
              alt="people"
            />
          </div>
        </div>

        <div className="_flex part-3">
          <div className="left">
            <h1 className="sub-title">
              Drive Growth and <br className="hidden-md" /> Success with
              Strategic Executive Recruitment
            </h1>
            <div className="description">
              Our executive search services are backed by years of experience in
              placing top-level executives across industries. We have a deep
              understanding of the unique skills, expertise, and qualities that
              exceptional leaders possess.
            </div>
          </div>
          <div className="right">
            <p className="description">
              Expert Evaluation and Selection: We go beyond resumes and
              qualifications. Our rigorous evaluation process includes in-depth
              assessments, interviews, and reference checks to ensure that we
              present you with the most suitable candidates.
            </p>
            <p className="description mt">
              Confidentiality and Discretion: We understand the sensitive nature
              of executive search. Your privacy is of utmost importance to us,
              and we handle the process with the highest level of
              confidentiality and discretion.
            </p>
          </div>
        </div>

        <ReadyBanner />
      </div>
      <Footer />
    </section>
  );
}

export default ExecutiveSearch;
