import "../styles/TalentAquisition.scss";
import "../styles/ServicesShared.scss";

import Footer from "../components/Footer";
import ServicesBoxes from "../components/ServicesBoxes";
import ReadyBanner from "../components/ReadyBanner";
function TalentAquisition() {
  return (
    <section className="talent-aquisition">
      <div className="container">
        <h1 className="main-title">
          UNLOCK YOUR SUCCESS <br className="hidden-md" /> WITH TOP TALENT
        </h1>
        <div className="accelerate">
          <h1>
            Accelerate Your <br className="hidden-md" /> Growth with Strategic{" "}
            <br className="hidden-md" />
            Talent Acquisition
          </h1>
          <p className="description">
            At Eboo, we specialize in sourcing and acquiring top talent that
            drives success. Our expert team is dedicated to finding the perfect
            candidates who possess the skills, experience, and cultural fit to
            elevate your organization. Discover how our talent acquisition
            solutions can help you unlock your full potential.
          </p>
        </div>

        <div className="customized">
          <h1 className="services-title">
            Customized Solutions <br /> Tailored to Your Unique Hiring Needs
          </h1>
          <ServicesBoxes />
        </div>

        <div className="accelerate">
          <h1>Industry insight and a track record of excellence</h1>
          <p className="description">
            With years of experience in talent acquisition, we specialize in
            various industries, including technology, digital marketing,
            healthcare, marketing, legal and more.
            <span className="block mt">
              Our industry-specific expertise allows us to understand your
              unique requirements and find candidates who excel in your field.
            </span>
          </p>
        </div>
        <ReadyBanner />
      </div>
      <Footer />
    </section>
  );
}

export default TalentAquisition;
