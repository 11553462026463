import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "../styles/ServicesTwoBoxes.scss";
export const data = [
  {
    title: "Executive Search",
    paragraph:
      "We specialize in identifying and recruiting high-level executives who possess the skills and expertise to lead your organization to new heights.",
  },
  {
    title: "HR Consulting",
    paragraph:
      "Our experienced consultants provide strategic HR guidance and support, helping you optimize your HR processes, improve employee engagement, and develop effective talent management strategies.",
  },
  {
    title: "Temporary Staffing",
    paragraph:
      "When you have short-term needs, our agency can provide you with skilled and reliable temporary staff to fill the gaps and keep your operations running smoothly.",
  },
  {
    title: "Talent Acquisition",
    paragraph:
      "Let us handle the entire recruitment process for you, from sourcing and screening to onboarding, saving you time and ensuring a seamless hiring experience.",
  },
  {
    title: "Recruitment Process Outsourcing",
    paragraph:
      "Let us handle the entire recruitment process for you, from sourcing and screening to onboarding, saving you time and ensuring a seamless hiring experience.",
  },
];

export default function ServicesTwoBoxes(props) {
  const ref = React.useRef();
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 5;
          if (parentWidth <= 1080) currentVisibleSlide = 3;

          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
    </div>
  );
}

export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { title, paragraph } = data[dataIndex];
  return (
    <div className="panel card stacked-card">
      <h1 className="title">{title}</h1>
      <p className="description">{paragraph}</p>
    </div>
  );
});
