import { Link } from "react-router-dom";
import "../styles/ReadyBanner.scss";

function ReadyBanner() {
  return (
    <>
      <div className="ready">
        <div className="content">
          <h1 className="section-title">Ready to unlock your success</h1>
          <p>
            Contact us today to find the top talent your organization needs. Let
            us help you build a winning team that drives growth and achieves
            your goals.
          </p>
          <div className="contact-btn">
            <Link to="/contact" className="btn">
              Contact Us
            </Link>
          </div>
        </div>
        <img src="/assets/images/eboo-rounded.png" alt="logo" />
      </div>
    </>
  );
}

export default ReadyBanner;
