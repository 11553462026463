import "../styles/ContactForm.scss";

function ContactForm() {
  return (
    <>
      <form
        className="contact-form"
        method="POST"
        name="contact-form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <label htmlFor="firstName">
          <span>
            NAME *<span className="hint">(FIRST AND LAST NAME)</span>
          </span>
          <input
            id="firstName"
            name="firstName"
            className="first-name form-input"
            type="text"
            placeholder="WRITE YOUR FULL NAME"
            required
          />
        </label>
        <label htmlFor="email">
          EMAIL *
          <input
            id="email"
            name="email"
            className="email form-input"
            type="email"
            placeholder="ENTER YOUR EMAIL"
            required
          />
        </label>
        <label htmlFor="phone">
          PHONE NUMBER *
          <input
            id="phone"
            name="phone"
            className="phone form-input"
            type="text"
            placeholder="ENTER YOUR PHONE NUMBER"
            required
          />
        </label>
        <label htmlFor="subject">
          SUBJECT *
          <input
            id="subject"
            name="subject"
            className="subject form-input"
            type="text"
            placeholder="SUBJECT"
            required
          />
        </label>
        <label htmlFor="message">
          MESSAGE *
          <textarea
            className="message form-input"
            name="message"
            id="message"
            placeholder="MESSAGE"
            rows={5}
            required
          ></textarea>
        </label>
        <input type="submit" className="btn" value="Contact Us" />
      </form>
    </>
  );
}

export default ContactForm;
