import "../styles/UnlockPower.scss";
import Footer from "../components/Footer";
import ReadyBanner from "../components/ReadyBanner";
function UnlockPower() {
  return (
    <section className="executive-search">
      <div className="container">
        <h1 className="main-title">
          UNLOCK THE POWER OF OUTSOURCED RECRUITMENT FOR YOUR BUSINESS
        </h1>
        <div className="grid part-1 gap-30">
          <div className="apply-images">
            <img
              className="main-image"
              src="/assets/images/computer-screen.png"
              alt="google search"
            />
            <img
              className="absolute-image"
              src="/assets/images/apply.png"
              alt="search bar"
            />
          </div>
          <div className="content">
            <h1 className="sub-title">
              Take the Stress Out of <br className="hidden-md" /> Hiring with
              Effective <br className="hidden-md" />
              RPO Services
            </h1>
            <div className="description">
              At Eboo, we understand that finding and hiring the right talent
              can be a time-consuming and complex process. Our Recruitment
              Process Outsourcing (RPO) service is designed to alleviate the
              burden and streamline your hiring process. We take care of the
              entire recruitment journey, from sourcing and screening to
              onboarding, allowing you to focus on what you do best—running your
              business.
            </div>
          </div>
        </div>
        <div className="grid part-2 gap-30">
          <div className="left">
            <h1 className="sub-title">
              <span className="block scalability">Scalability and Flexibility</span>
              Enhance Your <br className="hidden-md" /> Recruitment Strategy{" "}
              <br className="hidden-md mini-title" />
              with RPO
            </h1>
            <div className="description">
              Time and Cost Savings: Our RPO service saves you valuable time and
              resources by taking charge of the recruitment process. We employ
              efficient strategies and advanced tools to identify, attract, and
              screen top talent, ensuring a faster and more cost-effective
              hiring process.
            </div>
          </div>
          <div className="right">
            <p className="description">
              Access to Expertise: With our experienced recruitment
              professionals, you gain access to industry knowledge and best
              practices. We understand the market trends, possess extensive
              networks, and know how to attract and engage top candidates,
              giving you a competitive edge in securing the right talent.
            </p>
            <p className="description">
              Scalability and Flexibility: Our RPO service is adaptable to your
              changing hiring needs. Whether you require assistance for a single
              position or need to ramp up recruitment efforts for multiple
              roles, we can scale our services accordingly, ensuring a seamless
              hiring experience.
            </p>
          </div>
        </div>

        <div className="grid part-3 gap-30">
          <div className="team-images">
            <img
              className="main-image"
              src="/assets/images/laptop-2.png"
              alt="laptop"
            />
          </div>
          <div className="content">
            <h1 className="sub-title">
              Transform Your Talent <br className="hidden-md" /> Acquisition
              with <br className="hidden-md" />
              Professional RPO <br className="hidden-md" />
              Services
            </h1>
            <div className="description">
              At Eboo, we have a proven track record in managing the end-to-end
              recruitment process. Our team of recruitment experts leverages
              their industry knowledge and expertise to create tailored
              recruitment strategies that align with your organization's goals
              and culture. From crafting compelling job descriptions to
              conducting comprehensive candidate assessments, we handle every
              step of the process with precision and efficiency.
            </div>
          </div>
        </div>

        <ReadyBanner />
      </div>
      <Footer />
    </section>
  );
}

export default UnlockPower;
