import "../styles/HRConsulting.scss";
import Footer from "../components/Footer";
import ReadyBanner from "../components/ReadyBanner";

function HRConsulting() {
  return (
    <section className="hr-consulting">
      <div className="container">
        <h1 className="main-title">
          DRIVE ORGANIZATIONAL <br className="md-hidden" /> SUCCESS WITH
          STRATEGIC <br className="hidden-md" /> HR CONSULTING
        </h1>
        <div className="_flex gap-30">
          <p className="description">
            At Eboo, we understand that effective human resources management is
            crucial for the success of any organization. Our HR Consulting
            service offers strategic guidance and support to help you optimize
            your HR processes, improve employee engagement, and develop
            effective talent management strategies.
          </p>
          <p className="description">
            With our experienced consultants by your side, you can unlock the
            full potential of your workforce and drive organizational growth.
          </p>
        </div>

        <div className="streamlined">
          <h1 className="streamlined-title section-title">
            Streamlined HR Processes and <br className="hidden-md" /> Improved
            Efficiency
          </h1>

          <div className="_flex gap-30">
            <div className="card">
              <h1 className="title">
                Strategic HR <br className="hidden-md" /> Guidance
              </h1>
              <p className="description">
                Our HR consultants bring in-depth knowledge and expertise in
                various HR domains. We work closely with you to assess your
                current HR practices, identify areas for improvement, and
                develop tailored strategies that align with your organizational
                goals.
              </p>
            </div>
            <div className="card">
              <h1 className="title">
                Enhanced Employee <br className="hidden-md" /> Engagement
              </h1>
              <p className="description">
                We help you create a positive work environment and foster
                employee engagement. Our consultants provide insights and
                recommendations on employee relations, performance management,
                rewards and recognition, and organizational culture to boost
                employee satisfaction and productivity.
              </p>
            </div>
            <div className="card">
              <h1 className="title">
                Effective Talent <br className="hidden-md" /> Management
              </h1>
              <p className="description">
                With our HR consulting services, you can optimize your talent
                management strategies. We assist in talent acquisition,
                onboarding, training and development, succession planning, and
                retention initiatives to attract and retain top talent and build
                a high-performing workforce.
              </p>
            </div>
          </div>
        </div>

        <div className="_flex hr-power gap-30">
          <h1 className="sub-title">
            Unlock the Power of <br className="hidden-md" /> HR Consulting for{" "}
            <br className="hidden-md" />
            Success
          </h1>

          <div>
            <p className="description">
              At Eboo, our HR consultants have extensive experience in diverse
              industries and HR functions. We stay up-to-date with the latest HR
              trends and best practices to provide you with comprehensive
              guidance and support in all aspects of human resources management.
            </p>
          </div>
        </div>

        <ReadyBanner />
      </div>
      <Footer />
    </section>
  );
}

export default HRConsulting;
