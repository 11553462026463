import "../styles/ServicesBoxes.scss";

function ServicesBoxes() {
  return (
    <>
      <div className="services-boxes">
        <div className="card">
          <h1 className="title">Streamlined Hiring Process</h1>
          <p className="description">
            Our rigorous screening process ensures that only the most qualified
            candidates make it through, saving you time and resources in the
            hiring process.
          </p>
        </div>
        <div className="card">
          <h1 className="title">Access to Exceptional Candidates</h1>
          <p className="description">
            Tap into our vast network of top-tier talent across various
            industries and positions. We have a proven track record of
            connecting organizations with exceptional individuals who have the
            expertise to make an impact.
          </p>
        </div>
        <div className="card">
          <h1 className="title">Cultural Fit</h1>
          <p className="description">
            We understand the importance of cultural fit in driving long-term
            success. Our team focuses not only on candidate qualifications but
            also on finding individuals who align with your organization's
            values and vision.
          </p>
        </div>
        <div className="card">
          <h1 className="title">Customized Solutions</h1>
          <p className="description">
            We understand that every organization is unique. Our talent
            acquisition services are tailored to your specific needs, ensuring a
            personalized approach that aligns with your goals and culture.
          </p>
        </div>
      </div>
    </>
  );
}

export default ServicesBoxes;
