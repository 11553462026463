import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "../styles/Navbar.scss";

function Navbar() {
  const [showNavbar, setShowNavbar] = useState();
  const navbarRef = useRef(null);
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 760;

  function handleClick() {
    navigate("/");
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function handleClickOutside(event) {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      hideNavbar();
    }
  }

  const subServices = useRef(null);

  function hideSubMenu(event) {
    subServices.current.classList.remove("active");
    hideNavbar();
  }

  function showSubMenu(event) {
    subServices.current.classList.add("active");
  }

  function showSubMenuServices(event) {
    if (!isMobile) {
      subServices.current.classList.add("active");
    }
  }

  function toggleNavbar() {
    setShowNavbar(!showNavbar);
  }

  function hideNavbar() {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="container">
        <div className="logo-hamburger-wrapper">
          <div className="logo">
              <img onClick={handleClick} src="/assets/images/logo.png" alt="logo" />
          </div>
          <div className="hamburger">
            <img
              onClick={toggleNavbar}
              src="/assets/images/hamburger.png"
              alt="hamburger button"
            />
          </div>
        </div>
        <div className={`topnav-links ${showNavbar && "active"}`}>
          <div className={`links-wrapper ${showNavbar && "active"}`}>
            <Link to="/" className="link" onClick={hideNavbar}>
              <ReactSVG className="icon" src="/assets/icons/home.svg" />
              Home
            </Link>
            <div className="services-wrapper" onMouseLeave={hideSubMenu}>
              <div className="services-all">
                <Link
                  to="/services"
                  className="link services"
                  onMouseEnter={showSubMenuServices}
                  onClick={hideSubMenu}
                >
                  <div className="services-text link">
                    <ReactSVG className="icon" src="/assets/icons/wrench.svg" />
                    Services
                  </div>
                </Link>
                <ReactSVG
                  className="icon"
                  src="/assets/icons/arrow-down.svg"
                  onClick={showSubMenu}
                />
              </div>
              <div
                ref={subServices}
                id="servicesSubMenu"
                className="sub-services"
              >
                <Link
                  onClick={hideSubMenu}
                  to="/services/talent-acquisition"
                  className="link"
                >
                  <ReactSVG className="icon" src="/assets/icons/trophy.svg" />
                  Talent Acquisition
                </Link>

                <Link
                  onClick={hideSubMenu}
                  to="/services/executive-search"
                  className="link"
                >
                  <ReactSVG
                    className="icon"
                    src="/assets/icons/human-search.svg"
                  />
                  Executive Search
                </Link>

                <Link
                  onClick={hideSubMenu}
                  to="/services/temporary-staffing"
                  className="link"
                >
                  <ReactSVG className="icon" src="/assets/icons/human.svg" />
                  Temporary Staffing
                </Link>

                <Link
                  onClick={hideSubMenu}
                  to="/services/unlock-power"
                  className="link"
                >
                  <ReactSVG
                    className="icon"
                    src="/assets/icons/briefcase.svg"
                  />
                  Unlock the Power of Outsourced <br></br> Recruitment for Your
                  Business
                </Link>

                <Link
                  onClick={hideSubMenu}
                  to="/services/hr-consulting"
                  className="link"
                >
                  <ReactSVG
                    className="icon"
                    src="/assets/icons/double-human.svg"
                  />
                  HR Consulting
                </Link>
              </div>
            </div>
            <Link to="/about" className="link" onClick={hideNavbar}>
              <ReactSVG className="icon" src="/assets/icons/wave.svg" />
              About Us
            </Link>
            <Link to="/contact" className="link" onClick={hideNavbar}>
              <ReactSVG className="icon" src="/assets/icons/paint.svg" />
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
