import { Link } from "react-router-dom";
import "../styles/Home.scss";
import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";
import ContactUs from "../components/Contact-us";

import { ReactSVG } from "react-svg";
import ClientsReviews from "../components/ClientsReviews";
import Footer from "../components/Footer";
import ServicesTwoBoxes from "../components/ServicesTwoBoxes";
function Home() {
  return (
    <>
      <main>
        <div className="text">
          <div className="header-paragraph">
            <h1>UNLEASHING POTENTIAL,</h1>
            <h1>POWERING SUCCESS</h1>
            <p>
              Your Trusted Partner for Global Talent Acquisition, Outsourcing{" "}
              <br className="hidden-md" /> and HR Solutions
            </p>
          </div>
          <Link to="/contact" className="btn contact">
            Contact Us
          </Link>
          <div className="images">
            <img
              className="rocket"
              src="/assets/images/rocket.png"
              alt="rocket"
            />
            <img
              className="paper-plane"
              src="/assets/images/paper-plane.png"
              alt="paper plane"
            />
            <div className="chat-elements">
              <img className="chat" src="/assets/images/chat.png" alt="chat" />
              <img
                className="elements"
                src="/assets/images/elements.png"
                alt="elements"
              />
            </div>
            <img
              className="buildings-md"
              src="/assets/images/buildings.png"
              alt="chat"
            />
          </div>
          <img
            className="buildings-lg"
            src="/assets/images/buildings.png"
            alt="chat"
          />

          <div className="blur-rectangle"></div>
        </div>
      </main>

      <section className="services">
        <div className="container">
          <h1 className="section-title">Services</h1>
        </div>
        <div>
          <ServicesTwoBoxes />
        </div>
      </section>

      <section className="industries">
        <div className="container">
          <h1 className="section-title">INDUSTRIES WE SERVE</h1>
          <div className="cards">
            <div className="card card-pink">
              <span className="icon">
                <ReactSVG src="assets/icons/play.svg"></ReactSVG>
              </span>
              <span className="talent">300+ Talents</span>
              <h1 className="title">
                Digital Marketing <br></br> Agencies
              </h1>
              <p className="description">
                We understand the unique requirements of digital marketing
                agencies. Our recruitment solutions cater to marketing
                strategists, digital advertising specialists, SEO experts,
                social media managers, content creators, and other key roles
                that drive success in the digital realm.
              </p>
            </div>
            <div className="card card-blue">
              <span className="icon">
                <ReactSVG src="assets/icons/sattelite.svg"></ReactSVG>
              </span>
              <span className="talent">400+ Talents</span>
              <h1 className="title">
                Architecture & <br></br> Design
              </h1>
              <p className="description">
                Eboo serves the architecture and design industry, connecting
                talented professionals such as architects, interior designers,
                and landscape architects. We also work with building engineers,
                exhibition/event designers, visual architects, and product
                designers to meet your specialized needs.
              </p>
            </div>
            <div className="card card-pink">
              <span className="icon">
                <ReactSVG src="assets/icons/play.svg"></ReactSVG>
              </span>
              <span className="talent">300+ Talents</span>
              <h1 className="title">
                Media & <br></br> Entertainment
              </h1>
              <p className="description">
                In the dynamic world of media and entertainment, we provide
                comprehensive recruitment solutions. Our expertise covers video
                game development, advertising and product marketing, event
                planning, social media management, content creation, and video
                production.
              </p>
            </div>
            <div className="card card-orange">
              <span className="icon">
                <ReactSVG src="assets/icons/blockchain.svg"></ReactSVG>
              </span>
              <span className="talent">200+ Talents</span>
              <h1 className="title">
                Blockchain <br></br> & Web 3.0
              </h1>
              <p className="description">
                For businesses diving into the exciting realm of blockchain and
                Web 3.0, we offer specialized recruitment services. We connect
                you with skilled professionals, including blockchain developers,
                consultants, NFT artists, marketers, DeFi developers, wallet
                developers, and community managers.
              </p>
            </div>
            <div className="card card-yellow">
              <ReactSVG
                className="icon"
                src="assets/icons/medical-cross.svg"
              ></ReactSVG>
              <span className="talent">150+ Talents</span>
              <h1 className="title">
                Medical & <br></br> Health Services
              </h1>
              <p className="description">
                We cater to the medical and health services industry, assisting
                healthcare organizations in finding qualified professionals.
                From doctors, nurses, pharmacists, and medical laboratory
                technologists to public health professionals and medical
                managers, we ensure you have the right talent to deliver
                exceptional care.
              </p>
            </div>
            <div className="card card-dark-pink">
              <span className="icon">
                <ReactSVG src="assets/icons/cookwear.svg"></ReactSVG>
              </span>
              <span className="talent">300+ Talents</span>
              <h1 className="title">
                Hospitality <br></br> Industry
              </h1>
              <p className="description">
                Our agency understands the unique staffing requirements of the
                hospitality industry. We provide recruitment solutions for hotel
                managers, front desk clerks, housekeepers, kitchen staff, chefs,
                waiters/waitresses, and floor managers. Let us help you build a
                dedicated team that excels in guest satisfaction.
              </p>
            </div>
            <div className="card card-pink">
              <span className="icon">
                <ReactSVG src="assets/icons/play.svg"></ReactSVG>
              </span>
              <span className="talent">300+ Talents</span>
              <h1 className="title">
                Video <br></br> Production
              </h1>
              <p className="description">
                If you're in need of talented individuals for video production,
                animation, and graphic design projects, we've got you covered.
                Our agency connects you with skilled professionals who can bring
                your creative vision to life. Whether it's video editing,
                animation, or stunning graphic design, we're here to meet your
                needs.
              </p>
            </div>
            <div className="card card-blue">
              <span className="icon">
                <ReactSVG src="assets/icons/file.svg"></ReactSVG>
              </span>
              <span className="talent">400+ Talents</span>
              <h1 className="title">
                Legal <br></br> Services
              </h1>
              <p className="description">
                Unlock the power of a highly skilled legal workforce with our
                bespoke staffing solutions that is also designed for law firms.
                From seasoned attorneys to paralegals and support staff, we
                match you with professionals who bring unparalleled expertise to
                your practice.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div>
          <img
            className="about-logo"
            src="/assets/images/eboo-half.png"
            alt="logo"
          />
        </div>
        <div className="about">
          <h1 className="about-title section-title">About Us</h1>
          <p className="description">
            We’re a Miami-based recruiting and outsourcing agency specializing
            in providing quality worldwide resources. With expertise in various
            fields, we help businesses find the perfect talent to grow their
            operations and revenue. Our team of industry experts has a deep
            understanding of the market and a proven track record of success. We
            are passionate about connecting businesses with the right talent and
            creating mutually beneficial partnerships. At Eboo, we believe in
            building long-lasting relationships based on trust and transparency.
            We are committed to understanding your unique needs and providing
            tailored and vetted solutions that exceed your expectations.
          </p>
          <div className="learn-more-container">
            <Link to="/about" className="btn learn-more">
              Learn More
            </Link>
          </div>
        </div>
      </section>

      <section className="clients-reviews">
        <ClientsReviews />
      </section>

      <section className="contact-us">
        <ContactUs />
      </section>

      <Footer />
    </>
  );
}

export default Home;
