import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import "../styles/ClientReviews.scss";
// review, name, role, image
export const data = [
  {
    review:
      "We have nothing but amazing things to say about Eboo. Eboo is constantly helping us improve and expand our team whether hiring in Lebanon or in the US, Eboo has always provided us with top candidates. We definitely recommend them they are highly experienced and have a professional team.",
    name: "4 Media",
    role: "",
    image: "four-media.png",
  },
  {
    review:
      "Eboo provided us with timely contractors that were able to boost our project. They were able to hire a Tokenomist for our project that oversaw the finances of the tokens that we created in our project. Additionally, they provided us with top quality game developers that were able to develop our web 3 game on time.",
    name: "The Stoned Frogs",
    role: "",
    image: "the-stoned-frogs.png",
  },
  {
    review:
      "We met Eboo sometime ago and used their services in order to get out business up and running. They were able to provide us with service providers who were professional and great at what they did (Web development and Social Media Manager). Thanks to Eboo, our online business is now functioning smoothly.",
    name: "Istasherni",
    role: "",
    image: "istasherni.png",
  },
  {
    review:
      "Our experience with Eboo.io has been fabulous. The overall process is clear and concise, with excellent fast communication and utmost assistance. The team listened to what we wanted, and instead of just filling a role or checking a box, they gave us the best candidates out there. They understand our company's needs, what we are looking for in terms of candidates, and are always available for advice throughout the recruitment process.",
    name: "Sliq By Design",
    role: "",
    image: "sliq-by-design.png",
  },
];

function ClientsReviews() {
  const ref = React.useRef();
  return (
    <div className="container clients-reviews-container">
      <div className="header">
        <h1 className="section-title">CLIENTS & REVIEWS</h1>
        <p className="description">
          Don't just take our word for it. Hear what our clients have to say
          about their experience working with Eboo. Read their success stories
          and testimonials to understand how we have helped businesses find the
          right talent and resources to grow their operations and revenue.
        </p>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 5;
            if (parentWidth <= 1440) currentVisibleSlide = 5;
            if (parentWidth <= 1080) currentVisibleSlide = 3;

            return (
              <StackedCarousel
                className="review-carousel"
                ref={carouselRef}
                slideComponent={Review}
                slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
              />
            );
          }}
        />
      </div>
    </div>
  );
}

export default ClientsReviews;

export const Review = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { review, name, role, image } = data[dataIndex];
  return (
    <>
      <div className="reviews">
        <div className="review">
          <div className="review-logo">
            <img src={`/assets/images/${image}`} alt="review images" />
          </div>
          <p className="review-text">{review}</p>

          <div className="review-image">
            {/* <img src="/assets/images/review1.png" alt="google review profile" /> */}
          </div>
          <div className="review-name">{name}</div>
          <div className="review-role">{role}</div>
        </div>
      </div>
    </>
  );
});
