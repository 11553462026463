import React from "react";

import ClientsReviews from "../components/ClientsReviews";
import ContactUs from "../components/Contact-us";
import Footer from "../components/Footer";

import "../styles/About.scss";

function About() {
  return (
    <>
      <div className="container">
        <div className="main">
          <div className="section-title">
            <h1>
              ABOUT <br className="hidden-md"></br> US
            </h1>
          </div>
          <p className="description">
            Welcome to Eboo Recruitment Solutions, a Miami-based recruiting and
            outsourcing agency. Our mission is to provide exceptional
            recruitment and outsourcing solutions to businesses worldwide. With
            a focus on trust, transparency, flexibility, and efficiency, we help
            our clients find the right talent and resources to drive their
            growth and success.
          </p>
          <div className="history">
            <div className="title">
              <h1>OUR</h1>
              <h1>HISTORY</h1>
            </div>
            <p className="description">
              Founded by Michel Mansour and Joseph Moukarzel, Eboo has been
              serving clients in the various industries for nearly a decade.
              With a passion for connecting businesses with top talent, and
              developing tailor-made solutions for businesses, we have achieved
              significant milestones and built a reputation for excellence in
              the field.
            </p>
          </div>
          <div className="at-eboo">
            <div className="image">
              <img
                className="img-normal"
                src="/assets/images/elements-mini.png"
                alt="logo"
              />
              <img
                className="img-small-screen"
                src="/assets/images/elements-mini-small-screen.png"
                alt="logo"
              />
            </div>
            <div className="description-wrapper">
              <p className="description">
                At Eboo, we stand out from the competition through our fast and
                efficient recruitment process, diverse talent pool, and our due
                diligence process. And as industry players, we understand the
                needs of other businesses, and offer flexible payment options,
                in-depth market knowledge, and a dedicated team of experienced
                recruiters specialized in your niches.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="meet-the-team">
        <div className="container">
          <h1 className="section-title">Meet The Team</h1>
          <div className="members">
            <div className="member">
              <div className="image">
                <img
                  src="/assets/images/member-frame.png"
                  alt="member-frame"
                  className="frame"
                />
                <img
                  className="member-image"
                  src="/assets/images/michel-mansour.png"
                  alt="frame"
                />
              </div>
              <div className="name">
                <h1>Michel Mansour</h1>
              </div>
              <div className="role">Co-Founder and CEO</div>
              <div className="description">
                <p>
                  With years of industry experience, Michel leads our agency
                  with a vision for success and a commitment to delivering
                  exceptional results for our clients.
                </p>
              </div>
            </div>
            <div className="member">
              <div className="image">
                <img
                  src="/assets/images/member-frame.png"
                  alt="member-frame"
                  className="frame frame-2"
                />
                <img
                  className="member-image"
                  src="/assets/images/joseph-moukarzel.png"
                  alt="frame"
                />
              </div>
              <div className="name">
                <h1>Joseph Moukarzel</h1>
              </div>
              <div className="role">Co-Founder and COO</div>
              <div className="description">
                <p>
                  Joseph ensures the smooth operation of our agency, overseeing
                  the day-to-day activities and ensuring our clients receive
                  top-notch service and support.
                </p>
              </div>
            </div>
            <div className="member">
              <div className="image">
                <img
                  src="/assets/images/member-frame.png"
                  alt="member-frame"
                  className="frame frame-3"
                />
                <img
                  className="member-image"
                  src="/assets/images/veronica-maroun.png"
                  alt="frame"
                />
              </div>
              <div className="name">
                <h1>Veronica Maroun</h1>
              </div>
              <div className="role">Head of HR and Recruiting</div>
              <div className="description">
                <p>
                  Veronica leads our talented HR and recruiting team, leveraging
                  her expertise to identify and attract the best candidates who
                  perfectly match our clients' requirements.
                </p>
              </div>
            </div>
            <div className="member">
              <div className="image">
                <img
                  src="/assets/images/member-frame.png"
                  alt="member-frame"
                  className="frame frame-4"
                />
                <img
                  className="member-image"
                  src="/assets/images/anthony-el-hayek.png"
                  alt="frame"
                />
              </div>
              <div className="name">
                <h1>Anthony El Hayek</h1>
              </div>
              <div className="role">Head of Web 3.0 Department</div>
              <div className="description">
                <p>
                  Anthony brings deep knowledge of Web 3.0 technologies,
                  enabling our clients to stay ahead of the digital curve and
                  drive innovation in their respective industries.
                </p>
              </div>
            </div>
            <div className="member">
              <div className="image">
                <img
                  src="/assets/images/member-frame.png"
                  alt="member-frame"
                  className="frame frame-5"
                />
                <img
                  className="member-image"
                  src="/assets/images/theresa-ghoussoub.png"
                  alt="frame"
                />
              </div>
              <div className="name">
                <h1>Theresa Ghoussoub</h1>
              </div>
              <div className="role">Administrative Assistant</div>
              <div className="description">
                <p>
                  Theresa plays a vital role in ensuring the smooth functioning
                  of our agency, providing essential administrative support and
                  contributing to the overall efficiency of our operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="small-paragraph">
        <img src="/assets/images/eboo-cut.png" alt="eboo" />
        <div className="content">
          <h1 className="title">
            TRUST, TRANSPARENCY, FLEXIBILITY, AND EFFICIENCY ARE THE CORE VALUES
            THAT DRIVE OUR COMPANY CULTURE AT EBOO.
          </h1>
          <p className="description">
            We believe in building strong relationships with our clients and
            candidates, fostering open communication, and delivering exceptional
            service that exceeds expectations and lasts for years to come..
          </p>
        </div>
      </section>

      <section className="clients-reviews">
        <ClientsReviews />
      </section>

      <section className="contact-us">
        <ContactUs />
      </section>

      <Footer />
    </>
  );
}

export default About;
