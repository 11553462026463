import "../styles/Services.scss";
import ContactUs from "../components/Contact-us";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

function Services() {
  return (
    <section className="services">
      <div className="container">
        <div className="services-welcome">
          <h1 className="title">
            OUR <br className="hidden-md"></br>SERVICES
          </h1>
          <p className="description">
            Welcome to our Services page, where we offer a range of solutions to
            meet your business needs. From talent acquisition to HR consulting,
            our expert team is here to support your organization.
          </p>
        </div>
      </div>
      <div className="map-container">
        <img src="/assets/images/map.png" alt="locations map" />
      </div>

      <div className="container">
        <div className="services-unlock-success">
          <h1 className="title">
            Unlock Your <br className="hidden-md"></br> Success with{" "}
            <br className="hidden-md"></br> Top Talent
          </h1>
          <div className="description">
            <div className="description-title">
              <h1>Accelerate Your Growth with Strategic Talent Acquisition</h1>
            </div>
            <p className="description-text">
              At Eboo, we specialize in sourcing and acquiring top talent that
              drives success. Our expert team is dedicated to finding the
              perfect candidates who possess the skills, experience, and
              cultural fit to elevate your organization. Discover how our talent
              acquisition solutions can help you unlock your full potential.
            </p>
          </div>
        </div>
        <div className="customized-solutions">
          <h1 className="title">
            Customized Solutions <br></br>
            <span className="sub-title">
              Tailored to Your Unique Hiring Needs
            </span>
          </h1>

          <div className="solutions">
            <div className="card">
              <h1 className="title">Streamlined Hiring Process</h1>
              <p className="description">
                Our rigorous screening process ensures that only the most
                qualified candidates make it through, saving you time and
                resources in the hiring process.
              </p>
            </div>
            <div className="card">
              <h1 className="title">Access to Exceptional Candidates</h1>
              <p className="description">
                Tap into our vast network of top-tier talent across various
                industries and positions. We have a proven track record of
                connecting organizations with exceptional individuals who have
                the expertise to make an impact.
              </p>
            </div>
            <div className="card">
              <h1 className="title">Cultural Fit</h1>
              <p className="description">
                We understand the importance of cultural fit in driving
                long-term success. Our team focuses not only on candidate
                qualifications but also on finding individuals who align with
                your organization's values and vision.
              </p>
            </div>
            <div className="card">
              <h1 className="title">Customized Solutions</h1>
              <p className="description">
                We understand that every organization is unique. Our talent
                acquisition services are tailored to your specific needs,
                ensuring a personalized approach that aligns with your goals and
                culture.
              </p>
            </div>

            <div className="insight">
              <div className="title">
                <h1>
                  Industry insight and a track <br className="hidden-md"></br>
                  record of excellence
                </h1>
              </div>
              <p className="description">
                With years of experience in talent acquisition, we specialize in
                various industries, including technology, digital marketing,
                healthcare, marketing, and more. <br></br> Our industry-specific
                expertise allows us to understand your unique requirements and
                find candidates who excel in your field
              </p>
            </div>
            <div className="contact-card">
              <div className="call-to-action">
                Ready to unlock your success? Contact us today to find the top
                talent your organization needs. Let us help you build a winning
                team that drives growth and achieves your goals.
              </div>
              <div className="contact-btn">
                <Link to="/contact" className="btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="elevate-leadership">
            <h1 className="big-title">
              Elevate Your <br className="hidden-md" /> Leadership with{" "}
              <br className="hidden-md" /> Exceptional{" "}
              <br className="hidden-md" />
              Executives
            </h1>
          </div>
          <div className="description">
            <h1 className="description-title sub-title">
              Discover the Difference of Elite <br className="hidden-md"></br>{" "}
              Executive Search Services
            </h1>
            <p className="description-text paragraph">
              At Eboo, we specialize in executive search services designed to
              help you find visionary leaders who can take your organization to
              new heights. Our experienced team understands the critical role
              that executives play in driving success and growth. Discover how
              our executive search solutions can connect you with exceptional
              talent to elevate your leadership team.
            </p>
          </div>
          <div className="unparalleled-expertise long-card">
            <h1 className="big-title">
              Unparalleled Expertise in Identifying <br className="hidden-md" />
              Executive Talent
            </h1>
            <p className="long-card-description">
              Our executive search services are backed by years of experience in
              placing top-level executives across industries. We have a deep
              understanding of the unique skills, expertise, and qualities that
              exceptional leaders possess.
            </p>
          </div>
          <div className="drive-growth">
            <h1 className="sub-title">
              Drive Growth and Success with Strategic Executive Recruitment
            </h1>
            <p className="description paragraph">
              <span className="mt block">
                Access to Visionary Leaders: Our extensive network and targeted
                search strategies allow us to identify and recruit exceptional
                executives who possess the strategic vision and leadership
                qualities to propel your organization forward.
              </span>
              <span className="mt block">
                Expert Evaluation and Selection: We go beyond resumes and
                qualifications. Our rigorous evaluation process includes
                in-depth assessments, interviews, and reference checks to ensure
                that we present you with the most suitable candidates.
              </span>
              <span className="mt block">
                Confidentiality and Discretion: We understand the sensitive
                nature of executive search. Your privacy is of utmost importance
                to us, and we handle the process with the highest level of
                confidentiality and discretion.
              </span>
            </p>
            <div className="contact-card card">
              <div className="call-to-action">
                Ready to elevate your leadership? Contact us today to find the
                perfect executive for your organization. Let us help you build a
                strong leadership team that drives growth and success.
              </div>
              <div className="contact-btn">
                <Link to="/contact" className="btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="details-2">
          <div className="stay-agile">
            <h1 className="big-title">
              Stay Agile With <br className="hidden-md" /> Reliable Temporary
              <br className="hidden-md" /> Staffing Solutions
              <br></br>
            </h1>
            <h1 className="title-2">
              Access to On-Demand <br className="hidden-md" /> Specialized
              Skills
            </h1>
          </div>
          <div className="description title-description">
            <h1 className="description-title sub-title">
              Stay Agile with Reliable <br className="hidden-md"></br>Temporary
              Staffing Solutions
            </h1>
            <p className="description-text paragraph mt">
              At Eboo, we understand that businesses often face fluctuating
              demands and short-term staffing needs. Our Temporary Staffing
              service offers a reliable and agile solution to help you bridge
              the gaps and ensure uninterrupted operations.
              <span className="mt block">
                Whether you require additional support during peak seasons,
                special projects, or unexpected absences, our skilled temporary
                staff is ready to step in and contribute to your success.
              </span>
            </p>
          </div>
          <div>
            <h1 className="title-2-show-md">
              Access to On-Demand Specialized Skills
            </h1>
            <p>
              <span className="block paragraph">
                Flexibility and Agility: Our Temporary Staffing service provides
                you with the flexibility to scale your workforce up or down as
                per your changing needs. This ensures that you have the right
                number of skilled professionals to meet your business demands
                without the long-term commitments.
              </span>
              <span className="mt block paragraph">
                Quick Turnaround: We understand the urgency of your staffing
                requirements. Our extensive network of pre-screened temporary
                staff enables us to provide you with suitable candidates
                swiftly, minimizing downtime and allowing you to keep your
                operations running smoothly.
              </span>
              <span className="mt block paragraph">
                Skilled and Reliable Professionals: We pride ourselves on
                sourcing and vetting talented individuals who possess the
                necessary skills and expertise for the job. Our temporary staff
                members are reliable, adaptable, and equipped to hit the ground
                running, making an immediate impact on your team.
              </span>
            </p>
            <div className="contact-card card">
              <div className="call-to-action">
                Ready to experience the benefits of our Temporary Staffing
                service? Contact us today to discuss your staffing needs and let
                us provide you with the skilled professionals you need to keep
                your business moving forward.
              </div>
              <div className="contact-btn">
                <Link to="/contact" className="btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          <div className="temporary-staffing long-card">
            <h1 className="big-title">
              Experience <br className="hidden-md" /> Temporary
              <br className="hidden-md" /> Staffing <br className="hidden-md" />
              Excellence
            </h1>
            <p className="long-card-description paragraph">
              With years of experience in the staffing industry, we have
              developed a strong reputation for delivering reliable temporary
              staff to businesses across various sectors.
              <span className="mt block">
                Our team understands the unique challenges and dynamics of
                temporary staffing, allowing us to find the right candidates who
                seamlessly integrate into your team and contribute to your
                success.
              </span>
            </p>
            <img src="/assets/images/eboo-cut-2.png" alt="logo" />
          </div>
        </div>

        <div className="details-3">
          <div className="drive-organizational">
            <h1 className="big-title">
              Unlock the <br className="hidden-md" /> Power of
              <br className="hidden-md" /> Outsourced
              <br className="hidden-md" /> Recruitment for
              <br className="hidden-md" /> Your Business
            </h1>
          </div>
          <div className="description title-description">
            <h1 className="description-title sub-title">
              Take the Stress Out of Hiring with <br className="hidden-md" />
              Effective RPO Services
            </h1>
            <p className="description-text paragraph mt">
              At Eboo, we understand that finding and hiring the right talent
              can be a time-consuming and complex process. Our Recruitment
              Process Outsourcing (RPO) service is designed to alleviate the
              burden and streamline your hiring process. We take care of the
              entire recruitment journey, from sourcing and screening to
              onboarding, allowing you to focus on what you do best—running your
              business.
            </p>
          </div>
          <div className="transform-talent long-card">
            <h1 className="big-title">
              Transform <br className="hidden-md" /> Your Talent{" "}
              <br className="hidden-md" />
              Acquisition <br className="hidden-md" /> with{" "}
              <br className="hidden-md" /> Professional{" "}
              <br className="hidden-md" /> RPO Services
            </h1>
            <p className="description paragraph">
              At Eboo, we have a proven track record in managing the end-to-end
              recruitment process. Our team of recruitment experts leverages
              their industry knowledge and expertise to create tailored
              recruitment strategies that align with your organization's goals
              and culture. From crafting compelling job descriptions to
              conducting comprehensive candidate assessments, we handle every
              step of the process with precision and efficiency.
            </p>
          </div>
          <div className="scal-flex">
            <h1 className="sub-title">
              Scalability and Flexibility: Enhance Your Recruitment Strategy
              with RPO
            </h1>
            <p>
              <span className="block paragraph">
                Time and Cost Savings: Our RPO service saves you valuable time
                and resources by taking charge of the recruitment process. We
                employ efficient strategies and advanced tools to identify,
                attract, and screen top talent, ensuring a faster and more
                cost-effective hiring process.
              </span>
              <span className="mt block paragraph">
                Access to Expertise: With our experienced recruitment
                professionals, you gain access to industry knowledge and best
                practices. We understand the market trends, possess extensive
                networks, and know how to attract and engage top candidates,
                giving you a competitive edge in securing the right talent.
              </span>
              <span className="mt block paragraph">
                Scalability and Flexibility: Our RPO service is adaptable to
                your changing hiring needs. Whether you require assistance for a
                single position or need to ramp up recruitment efforts for
                multiple roles, we can scale our services accordingly, ensuring
                a seamless hiring experience.
              </span>
            </p>
            <div className="contact-card card">
              <div className="call-to-action">
                Ready to optimize your HR processes and drive organizational
                success? Contact us today to discuss your HR consulting needs
                and let our experts guide you towards effective HR management.
              </div>
              <div className="contact-btn">
                <Link to="/contact" className="btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="details-4">
          <div className="drive-organizational">
            <h1 className="big-title">
              Drive Organizational <br className="hidden-md" /> Success with{" "}
              <br className="hidden-md" />
              Strategic HR
              <br className="hidden-md" />
              Consulting
              <br className="hidden-md" /> Staffing Solutions
              <br></br>
            </h1>
            <h1 className="title-2">
              Streamlined HR Processes and Improved Efficiency
            </h1>
          </div>
          <div className="description title-description">
            <h1 className="description-title sub-title">
              Drive Success Through <br className="hidden-md" /> Effective HR
              Management
            </h1>
            <p className="description-text paragraph mt">
              At Eboo, we understand that effective human resources management
              is crucial for the success of any organization. Our HR Consulting
              service offers strategic guidance and support to help you optimize
              your HR processes, improve employee engagement, and develop
              effective talent management strategies.
              <span className="mt block">
                With our experienced consultants by your side, you can unlock
                the full potential of your workforce and drive organizational
                growth.
              </span>
            </p>
          </div>
          <div>
            <h1 className="title-2-show-md">
              Streamlined HR Processes and Improved Efficiency
            </h1>
            <p>
              <span className="block paragraph">
                Strategic HR Guidance: Our HR consultants bring in-depth
                knowledge and expertise in various HR domains. We work closely
                with you to assess your current HR practices, identify areas for
                improvement, and develop tailored strategies that align with
                your organizational goals.
              </span>
              <span className="mt block paragraph">
                Enhanced Employee Engagement: We help you create a positive work
                environment and foster employee engagement. Our consultants
                provide insights and recommendations on employee relations,
                performance management, rewards and recognition, and
                organizational culture to boost employee satisfaction and
                productivity.
              </span>
              <span className="mt block paragraph">
                Effective Talent Management: With our HR consulting services,
                you can optimize your talent management strategies. We assist in
                talent acquisition, onboarding, training and development,
                succession planning, and retention initiatives to attract and
                retain top talent and build a high-performing workforce.
              </span>
            </p>
            <div className="contact-card card">
              <div className="call-to-action">
                Ready to optimize your HR processes and drive organizational
                success? Contact us today to discuss your HR consulting needs
                and let our experts guide you towards effective HR management.
              </div>
              <div className="contact-btn">
                <Link to="/contact" className="btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          <div className="unlock-power long-card">
            <h1 className="big-title">
              Unlock the <br className="hidden-md" /> Power of HR
              <br className="hidden-md" /> Consulting for
              <br className="hidden-md" /> Success
            </h1>
            <p className="description paragraph">
              At Eboo, our HR consultants have extensive experience in diverse
              industries and HR functions. We stay up-to-date with the latest HR
              trends and best practices to provide you with comprehensive
              guidance and support in all aspects of human resources management.
            </p>
            <img src="/assets/images/eboo-cut-2.png" alt="logo" />
          </div>
        </div>
      </div>

      <div className="contact-us">
        <ContactUs />
      </div>

      <Footer />
    </section>
  );
}

export default Services;
