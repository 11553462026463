import "../styles/Contact.scss";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import ClientReviews from "../components/ClientsReviews";

function Contact() {
  return (
    <section>
      <div className="container">
        <div className="flex">
          <div className="left">
            <h1 className="title">LET'S TALK</h1>
            <p className="description">
              Let's Dive In! Engage with us and explore the possibilities.
              Whether you're seeking expert recruiting advice, ready to start
              the process, or just curious about our offerings, this is where
              the conversation begins. Welcome to a world of limitless
              potential!
            </p>
            <h2 className="subtitle">Talk to our team today to:</h2>
            <ul>
              <li>Provide you with Expert Guidance</li>
              <li>Inform you on our tailored solutions</li>
              <li>Help you save time and money</li>
            </ul>
          </div>
          <div className="right">
            <ContactForm></ContactForm>
          </div>
        </div>
        <div className="reviews">
          <ClientReviews />
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Contact;
