import "../styles/ContactUs.scss";
import ContactForm from "./ContactForm";

function ContactUs() {
  return (
    <div className="container">
      <h1 className="section-title">Contact Us</h1>
      <div className="content">
        <div className="image">
          <img src="/assets/images/eboo-full.png" alt="eboo" />
        </div>
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactUs;
