import { Link } from "react-router-dom";
import "../styles/Footer.scss";
import { ReactSVG } from "react-svg";

function Footer() {
  return (
    <footer>
      <div className="buildings">
        <img src="/assets/images/buildings.png" alt="buildings" />
      </div>
      <div className="footer-content">
        <div className="links-container">
          <ul className="links">
            <li className="link">
              <Link to="/" className="link">
                Home
              </Link>
            </li>
            <li className="link">
              <Link to="/services" className="link">
                Services
              </Link>
            </li>
            <li className="link">
              <Link to="/about" className="link">
                About Us
              </Link>
            </li>
            <li className="link">
              <Link to="/contact" className="link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="subscription">
          <div className="title">
            <h1>Subscribe to our newsletter</h1>
            <h1>to stay up to date!</h1>
          </div>
          <form
            name="newsletter"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="newsletter" />
            <input
              className="email-input required email"
              type="email"
              placeholder="Email Address"
              name="email"
              required
            />
            <button type="submit" className="btn">
              Subscribe
            </button>
          </form>
          <div className="emailLocation">
            <div className="email">
              <ReactSVG className="icon" src="/assets/icons/mail.svg" />
              <div className="content">
                <div className="header">Email</div>
                <div className="data">
                  <a href="mailto:contact@eboo.io" target="_blank" rel="noreferrer">contact@eboo.io</a>
                </div>
              </div>
            </div>
            <div className="location">
              <ReactSVG className="icon" src="/assets/icons/pin.svg" />
              <div className="content">
                <div className="header">Location</div>
                <div className="data">Miami, Florida, USA</div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-media-links">
          <Link
            target="_blank"
            to="https://www.facebook.com/Eboo.services?mibextid=ZbWKwL"
            className="link"
          >
            <img src="/assets/images/facebook.png" alt="facebook logo" />
          </Link>
          <Link
            target="_blank"
            to="https://twitter.com/Ebooio?s=08"
            className="link"
          >
            <img src="/assets/images/twitter.png" alt="twitter logo" />
          </Link>
          <Link
            target="_blank"
            to="https://www.linkedin.com/company/eboo-io/"
            className="link"
          >
            <img src="/assets/images/linkedin.png" alt="linkedin logo" />
          </Link>
          <Link
            target="_blank"
            to="https://instagram.com/eboo.io?igshid=MzRlODBiNWFlZA=="
            className="link"
          >
            <img src="/assets/images/instagram.png" alt="instagram logo" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
