import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import TalentAquisition from "./pages/TalentAquisition";
import ExecutiveSearch from "./pages/ExecutiveSearch";

import "./App.scss";
import TempStaffing from "./pages/TempStaffing";
import HRConsulting from "./pages/HRConsulting";
import UnlockPower from "./pages/UnlockPower";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const onPageLoad = () => {
      setLoading(false)
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  },[]);

  return isLoading ? (
    <div id="preLoader">
      <img className="preLoader-image" src="/preloader.png" alt="app loader" />
    </div>
  ) : (
    <Router scrollRestoration="manual">
      <ScrollToTop />
      <div>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="about" element={<About />}></Route>
            <Route exact path="services" element={<Services />}></Route>
            <Route path="contact" element={<Contact />}></Route>

            <Route
              path="/services/talent-acquisition"
              element={<TalentAquisition />}
            ></Route>
            <Route
              path="/services/executive-search"
              element={<ExecutiveSearch />}
            ></Route>
            <Route
              path="/services/temporary-staffing"
              element={<TempStaffing />}
            ></Route>
            <Route
              path="/services/unlock-power"
              element={<UnlockPower />}
            ></Route>
            <Route
              path="/services/hr-consulting"
              element={<HRConsulting />}
            ></Route>
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
